<template>
  <div id="app" class="container-fluid">
    <div class="container form py-12 px-8">
      <div class="mb-12 row justify-content-between align-items-center">
        <img :src="require('@/assets/logo.svg')" class="logo col-3"/>     
        <h1>Think Local First Ad Campaign</h1>
      </div>
      <div class="bg-light-grey p-4 rounded">
        <div :class="fieldClass">
          <label :class="labelClass">Business Name</label>
          <u-textfield 
            class="col" 
            v-model="form.company.value" 
            :error="form.company.error"
            @input="form.company.error = false"
            @blur="companyBlur"
            tabindex="1" 
          />
        </div>
        <div :class="fieldClass">
          <label :class="labelClass">Contact Name</label>
          <u-textfield 
            class="col" 
            v-model="form.contact.value" 
            :error="form.contact.error" 
            @input="form.contact.error = false"
            @blur="contactBlur"
            tabindex="2" 
          />
        </div>
        <div :class="fieldClass">
          <label :class="labelClass">Contact Email</label>
          <u-textfield 
            class="col" 
            v-model="form.email.value" 
            :error="form.email.error" 
            @input="form.email.error = false"
            @blur="emailBlur"
            tabindex="3" 
          />
        </div>
        <div :class="fieldClass">
          <label :class="labelClass">Creative</label>
          <div id="upload-creative" class="rounded bg-white col p-2 text-grey" @click="startCreativeUpload">{{form.creative.value ? form.creative.value.name : 'Upload image (optional)'}}</div>
          <input type="file" class="d-none" ref="creativeInput" @change="uploadCreative" />
        </div>
        <div :class="fieldClass">
          <label :class="labelClass"></label>
          <div class="col d-flex align-items-center">
            <u-checkbox 
              max-width="2.4rem"
              v-model="form.upgrade.value"
              color="primary" 
              class="col text-xs flex-grow-1" 
              @change="form.upgrade.error = false"
              
            />
            <span class='col text-700 ml-4 flex-grow-1' @click="form.upgrade.value = !form.upgrade.value">Think Local First one-week cooperative ad campaign $99 (plus tax)</span>
          </div>
          <span v-if="form.upgrade.error" class="col-12 text-right text-xs text-warning">{{form.upgrade.error}}</span>
        </div>
        <div :class="fieldClass" >
          <label :class="labelClass">Card Holder Name</label>
          <div class="inputs col d-flex flex-nowrap">
            <u-textfield 
              placeholder="Card holder name" 
              v-model="form.name.value" 
              :error="form.name.error" 
              tabindex="6" 
              @blur="nameBlur"
              @input="form.name.error = false"
            />
          </div>
        </div>
        <div :class="fieldClass">
          <label :class="labelClass">Credit Card</label>
          <u-textfield 
            id="credit-card-number" 
            ref="number" 
            class="col" 
            v-model="form.number.value" 
            :error="form.number.error" 
            @blur="numberBlur"
            @input="cardNumberInput" 
            tabindex="7" 
          />
        </div>
        <div class="row flex-nowrap">
          <div :class="fieldClass" class="col-6">
            <label :class="labelClass" class="col-lg-4 flex-grow-1">Expiry</label>
            <u-textfield 
              ref="expiry" 
              class="col" 
              placeholder="MM YY" 
              v-model="form.expiry.value" 
              :error="form.expiry.error" 
              @input="cardExpiryInput" 
              @blur="expiryBlur"
              tabindex="8" 
            />
          </div>
          <div :class="fieldClass" class="col-6">
            <label :class="labelClass" class="ml-4 col-lg-1 flex-grow-1">CVC</label>
            <u-textfield 
              ref="cvc" 
              class="ml-4 ml-lg-0 col flex-shrink-1" 
              v-model="form.cvc.value" 
              :error="form.cvc.error" 
              @input="cardCvcInput" 
              @blur="cvcBlur"
              tabindex="9" 
            />
          </div>
        </div>
        <div class="row justify-content-center mt-4" :class="{'animate-headShake': invalid}" @animationend="invalid=false">
          <u-button :color="invalid ? 'warning': 'accent'" tabindex="10" @click="review" 
            >Review Order</u-button>
        </div>
        <BamboraForm 
          :number="form.number.value"
          :cvv="form.cvc.value"
          :expiry="form.expiry.value"
          :get-token="getBamboraToken"
          @done="gotTokenResponse"
        />
      </div>
    </div>
    <u-modal v-model="modal" max-height="90vh" :hide-close="modalClose" manual-close >
      <div class="container-fluid">
        <PurchaseConfirmation v-if="state == processing_state.start" :class="processingClass" :purchase="purchase" @confirm="submit" />
        <div v-else-if="state == processing_state.processing" :class="processingClass">
          <div class="col-auto h2 flex-shrink-1 text-center">
            Processing, please wait...
          </div>
          <div class="col flex-grow-1 d-flex align-items-center justify-content-center">
            <Spinner />
          </div>
        </div>
        <PurchaseSuccess v-else-if="state == processing_state.success" :class="processingClass" :purchase="purchase" />
        <PurchaseError v-else-if="state == processing_state.error" :class="processingClass" :purchase="purchase" />
      </div>
    </u-modal>
    <Recaptcha ref="captcha" :onSubmit="processPayment" />
  </div>
</template>

<script>
import BamboraForm from '@/components/BamboraForm.vue'
import Spinner from '@/components/Spinner.vue'
import PurchaseSuccess from '@/components/PurchaseSuccess.vue'
import PurchaseError from '@/components/PurchaseError.vue'
import PurchaseConfirmation from '@/components/PurchaseConfirmation.vue'
import Recaptcha from '@/components/Recaptcha.vue'
import axios from 'axios'

// error fields 
const ERROR = {
  companyRequired: "Company name is required",
  emailRequired: "Email address is required",
  emailInvalid: "Email address is indvalid",
  contactRequired: "Contact name is required",
  postalCodeRequired: "Postal code is required",
  nameRequired: "Card holder name is required",
  numberRequired: "Card number is required",
  upgradeRequired: "Please select upgrade to purchase",
  numberInvalid: "Card number is invalid",
  expiryRequired: "Expiry date is required",
  expiryInvalid: "Expiry date is invalid",
  expiryExpired: "Card expiry date has passed",
  cvcRequired: "CVC is required",
  cvcInvalid: "CVC is invalid"
}

const TEST = {
  success: {
    number: "4030000010001234",
    cvv: "123"
  },
  decline: {
    number: "4003050500040005",
    cvv:"123"
  }
}
const PROCESSING_STATE = {
  start: 1,
  processing: 2,
  success: 3,
  error: 4
}

export default {
  name: 'LocalWorks',
  components: {
    BamboraForm,
    Spinner,
    PurchaseConfirmation,
    PurchaseSuccess,
    PurchaseError,
    Recaptcha
  },
  data () {
    return {
      fieldClass: "fields row align-items-center mb-4",
      labelClass: "text-700 text-sm mr-4 label col-lg-2 flex-grow-1",
      processingClass: "row flex-column align-items-stretch p-12 justify-content-center processing h-100",
      form: {
        company: {
          value: "",
          error: false,
        },
        contact: {
          value: "",
          error: false
        },
        email: {
          value: "",
          error: false,
        },
        upgrade: {
          value: true,
          error: false,
        },
        postalCode: {
          value: "",
          error: false
        },
        creative:{
          value: "",
          error:false
        },
        name: {
          value: "",
          error: false,
        },
        number: {
          value: "",
          error: false
        },
        cvc: {
          value: "",
          error: false
        },
        expiry: {
          value: "",
          error: false
        },
      },
      purchase: {}, // will need the following fields [transaction_id, date, price, tax, total, email, last_four]
      getBamboraToken: false,
      cardType: null,
      invalid: false,
      modal: false,
      processing_state: PROCESSING_STATE,
      state: null,
    }
  },
  computed: {
    modalClose() {
      // if true we hide the close button
      // else we show close button
      let hideClose = true
      // console.log(this.state , PROCESSING_STATE.success)
      if (this.state == PROCESSING_STATE.start) hideClose = false
      // if (this.state == PROCESSING_STATE.success) hideClose = false
      if (this.state == PROCESSING_STATE.error) hideClose = false
      return hideClose
    }
  },
  methods: {
    processPayment () {
      this.getBamboraToken = true
    },
    review() {
      if (this.validate()) {
        axios({
          url: `${process.env.VUE_APP_NOVA_URL}/upgrade_purchase_local_link/`,
          method: "GET",
        }).then(response => {
          this.modal = true
          this.state = PROCESSING_STATE.start
          this.purchase = response.data
        }).catch(error => console.log('error get', error))
      } else {
        this.invalid = true
      }
    },
    submit() {
      this.$refs.captcha.execute()
    },
    validate () {
      this.companyBlur()
      this.emailBlur()
      this.contactBlur()
      this.upgradeValidate()
      
      this.nameBlur()
      this.numberBlur()
      this.expiryBlur()
      this.cvcBlur()
      return Object.keys(this.form).every(key => this.form[key].error === false)
    },
    companyBlur() {
      if (this.form.company.value.length == 0) {
        this.form.company.error = ERROR.companyRequired
      } else {
        this.form.company.error = false
      }
    },
    contactBlur () {
      if(this.form.contact.value.length == 0) {
        this.form.contact.error = ERROR.contactRequired
      }
    },
    emailBlur() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.form.email.value.length == 0) {
        this.form.email.error = ERROR.emailRequired
      } else if (!re.test(String(this.form.email.value).toLowerCase())) {
        this.form.email.error = ERROR.emailInvalid
      } else {
        this.form.email.error = false
      }
    },
    upgradeValidate() {
      if (this.form.upgrade.value == false) {
        this.form.upgrade.error = ERROR.upgradeRequired
      }
    },
    nameBlur() {
      if (this.form.name.value.length == 0) {
        this.form.name.error = ERROR.nameRequired
      } else {
        this.form.name.error = false
      }
    },
    numberBlur() {
      if (this.form.number.value.length == 0) {
        this.form.number.error = ERROR.numberRequired
      } else if (this.form.number.value.length < this.getCardNumberLength()) {
        this.form.number.error = ERROR.numberInvalid
      } else {
        this.form.number.error = false
      }
    },
    expiryBlur() {
      if (this.form.expiry.value.length == 0) {
        this.form.expiry.error = ERROR.expiryRequired
      } else if (this.form.expiry.value.replace(' ', '').length != 4) {
        this.form.expiry.error = ERROR.expiryInvalid
      } else {
        let now = new Date()
        let expiry = this.form.expiry.value.replace(' ', '')
        let cardMonth = parseInt(expiry.substring(0, 2))
        let cardYear = parseInt(expiry.substring(2, 4))
        let month = now.getMonth()
        let year = now.getFullYear() % 100
        // we cannot just check if the current year is less than the card year
        // as a scenario can be that the current year is 99 and the card year is 00
        if ((cardYear < year && year - cardYear < 80) || (cardYear > year && cardYear - year > 80) || (cardYear == year && cardMonth < month)) {
          this.form.expiry.error = ERROR.expiryExpired
        } else {
          this.form.expiry.error = false
        }
      } 
    },
    cvcBlur() {
      if (this.form.cvc.value.length == 0) {
        this.form.cvc.error = ERROR.cvcRequired
      } else if (this.form.cvc.value.length != this.getCardCvcLength()) {
        this.form.cvc.error = ERROR.cvcInvalid
      } else {
        this.form.cvc.error = false
      }
    },
    postalCodeBlur () {
      if(this.form.postalCode.value.length == 0) {
        this.form.postalCode.error = ERROR.postalCodeRequired
      }
    },
    cardNumberInput(value) {
      this.form.number.error = false
      let val = value.replace(/[^0-9]/gi, '')
      if (/^4.*$/.test(val)) {
        this.cardType = 'visa'
      } else if (/^(5[1-5]|2[2-7]).*$/.test(val)) {
        this.cardType = 'mastercard'
      } else if (/^(34|37).*$/.test(val)) {
        this.cardType = 'amex'
      } else {
        this.cardType = null
      }
      val = val.substring(0, this.getCardNumberLength())
      let parts = []
      let current = 0
      while (true) {
        if (current >= val.length) break
        let step = 4
        if (this.cardType == 'amex') {
          if (parts.length == 1) step = 6
          else if (parts.length == 2) step = 5
        }
        parts.push(val.substring(current, current + step))
        current += step
      }
      this.form.number.value = parts.join(' ')
    },
    cardExpiryInput(value) {
      this.form.expiry.error = false
      let val = value.replace(/[^0-9]/gi, '')
      val = val.substring(0, 4)
      if (val.length == 1 && parseInt(val) > 1) {
        val = ''
      } else if (val.length > 1) {
        let month = parseInt(val.substring(0, 2))
        if (month == 0) {
          val = ''
        } else if (month > 12) {
          if (parseInt(val.substring(0, 1)) < 2) val = val.substring(0, 1)
          else val = ''
        }
      }
      this.form.expiry.value = val
      val = val.split('')
      if (val.length > 2) val.splice(2, 0, ' ')
      this.form.expiry.value = val.join("")
      this.$refs.expiry.$el.getElementsByTagName('input')[0].value = val.join('')
    },
    cardCvcInput(value) {
      this.form.cvc.error = false
      let val = value.replace(/[^0-9]/gi, '')
      val = val.substring(0, this.getCardCvcLength())
      this.form.cvc.value = val
      this.$refs.cvc.$el.getElementsByTagName('input')[0].value = val
    },
    getCardNumberLength() {
      return this.cardType == 'amex' ? 15 : 16
    },
    getCardCvcLength() {
      return this.cardType == 'amex' ? 4 : 3
    },
    gotTokenResponse(bamboraResponse) {
      if (bamboraResponse) {
        // setup data of bambora response to server
        let data = new FormData()
        data.append('token', bamboraResponse.token)
        data.append('company', this.form.company.value)
        data.append('email', this.form.email.value)
        data.append('contact', this.form.contact.value)
        data.append('name', this.form.name.value)

        if (this.form.creative.value) data.append('creative', this.form.creative.value)
        this.state = PROCESSING_STATE.processing
        axios({
          url: `${process.env.VUE_APP_NOVA_URL}/upgrade_purchase_local_link/`,
          method: "POST",
          data: data
        }).then(result=> {
          this.state = PROCESSING_STATE.success
          this.purchase = result.data
        }).catch(error => {
          this.state = PROCESSING_STATE.error
          // console.log(error, error.response.data)
          if ('purchase' in error.response.data) {
            this.purchase = error.response.data.purchase
            // console.log('purchase', this.purchase)
          }
        })
      }
      this.getBamboraToken = false
    },
    startCreativeUpload() {
      this.$refs.creativeInput.click()
    },
    uploadCreative (event) {
      var files = event.target.files || event.dataTransfer.files;
      if (files.length) {
        this.form.creative.value =  files[0]
      }
    }
  },
  watch: {
    cardType: function(newVal, oldVal) {
      if (newVal != oldVal) {
        let backgroundImage = newVal ? "url('https://cdn.na.bambora.com/downloads/images/cards/" + newVal + ".svg')" : newVal
        this.$refs.number.$el.getElementsByTagName('input')[0].style.backgroundImage = backgroundImage
      }
    },
  }
}
</script>

<style lang="scss">
.logo {
  max-width:12rem !important;
}
.label {
  white-space: nowrap;
}
.processing {
  min-height:50vh;
  min-width:50vw;
}
#upload-creative {
  cursor: pointer;
}
#credit-card-number {
  background-repeat: no-repeat;
  background-size: contain;
  background-origin: content-box;
  background-position-x: 100%;
}
input:-webkit-autofill {
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0px 1000px $white inset;
  transition: background-color 5000s ease-in-out 0s;
}
button {
  transition: all 0.005s linear;
}
</style>
