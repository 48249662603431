import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import ReusableUI from "reusable-ui";
import "@/assets/main.scss";
Vue.use(ReusableUI);

new Vue({
  render: h => h(App),
}).$mount('#app')
