<template>
    <div
      :id="recaptchaId"
      
      :data-sitekey="reCAPTCHA_site_key"
      :data-callback="callbackName"
      data-badge="bottomright"
      data-size="invisible"
      >
    </div>
</template>

<script>
const RECAPTCHA_SITE_KEY = '6LerkbsZAAAAAA565ieppg0OW74J7YSe58N7MiEK'

export default {
  data () {
    return {
      reCAPTCHA_site_key: RECAPTCHA_SITE_KEY,
      widgetId: null,
      rendered: false
    }
  },
  beforeMount () {
    window[this.callbackName] = this.onSubmit
    window.recaptchaOnloadCallback = this.recaptchaOnloadCallback
  },
  beforeDestroy () {
    delete window[this.callbackName]
  },
  mounted () {
  },
  props: {
    onSubmit: {
      type: Function,
      required: true
    },
    name: {
      type: String,
      default: 'locallink'
    }
  },
  computed: {
    callbackName: function () {
      return `${this.name}RecaptchaCallback`
    },
    recaptchaId: function () {
      return `${this.name}-recaptcha`
    }
  },
  methods: {
      recaptchaOnloadCallback () {
          grecaptcha.render(this.recaptchaId)
      },
      execute() {
        grecaptcha.execute()
      },
  }
}
</script>
