<template>
  <div>
    <div id="card-number" class="bambora-iframe" ref="cardnumber"></div>
    <div id="card-cvv" class="bambora-iframe" ref="cardcvv"></div>
    <div id="card-expiry" class="bambora-iframe" ref="cardexpiry"></div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      customCheckout: null
    }
  },
  props: {
    getToken: {
      type: Boolean,
      required: true
    },
    number: {
      type: String,
      required: true
    },
    cvv: {
      type: String,
      required: true
    },
    expiry: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.customCheckout = customcheckout()
    this.customCheckout.create('card-number').mount('#card-number')
    this.customCheckout.create('cvv').mount('#card-cvv')
    this.customCheckout.create('expiry').mount('#card-expiry')
  },
  methods: {
    bamboraCallback(result) {
      if(result.token) {
        this.$emit('done', {token: result.token})
      } else {
        this.$emit('done', false)
      }
    }
  },
  watch: {
    getToken: function(val) {
      if (val) {
        console.log('getToken', this.number, this.cvv, this.expiry)
        this.$refs.cardnumber.children[0].contentWindow.postMessage({name: 'changeValue', details: {newValue: this.number}}, 'https://libs.na.bambora.com/customcheckout/1.0.2/iframe.html')
        this.$refs.cardcvv.children[0].contentWindow.postMessage({name: 'changeValue', details: {newValue: this.cvv}}, 'https://libs.na.bambora.com/customcheckout/1.0.2/iframe.html')
        this.$refs.cardexpiry.children[0].contentWindow.postMessage({name: 'changeValue', details: {newValue: this.expiry.replace(" ", "")}}, 'https://libs.na.bambora.com/customcheckout/1.0.2/iframe.html')
        this.customCheckout.createToken(this.bamboraCallback)
      }
    },
  }
}
</script>

<style lang="sass">
.bambora-iframe
  height: 0
  > iframe
    height: 0 !important
</style>