<template>
  <div>
    <img :src="require('@/assets/logo.svg')" class="logo col-3"/>
    <div class="col-auto h3 text-700 flex-shrink-1 text-center" style="letter-spacing:-0.1rem">
        Confirm Purchase
    </div>

    <div class="col mt-4 flex-grow-1 border rounded bg-light-grey d-flex justify-content-around flex-column">
        <div class="col flex-grow-1 p-4 d-flex justify-content-between">
            <span class="text-700">{{purchase.upgrade}}</span>
            <span class="">${{purchase.price}}</span>
        </div>
        <div class="col-auto tax d-flex justify-content-between p-4 flex-shrink-1">
            <span class="text-700">Tax</span><span>${{purchase.tax}}</span>
        </div>
        <div class="col-auto total d-flex justify-content-between p-4 border-top flex-shrink-1">
            <span class="text-700">Total</span><span>${{purchase.total}}</span>
        </div>
    </div>
    <div class="col text-center mt-4">
        <u-button color='accent' @click="confirm">Confirm Purchase</u-button>
    </div>

  </div>
</template>

<script>
export default {
    props: {
        purchase: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        confirm() {
            this.$emit('confirm')
        }
    }
}
</script>

<style>

</style>