<template>
  <div class="loader" :style="{
    width: size,
    height: size
  }"></div>
</template>
<script>
export default {
  name: "Spinner",
  props: {
    size: {
      type:String,
      default: "120px"
    }
  }
};
</script>
<style lang="scss">
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #32cbad;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
