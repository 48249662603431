<template>
  <div>
    <img :src="require('@/assets/logo.svg')" class="logo col-3"/>
    <div class="col-auto h3 text-700 flex-shrink-1 text-center" style="letter-spacing:-0.1rem">
        Payment Receipt
    </div>
    <div class="col-auto text-center text-100">
        {{purchaseDate}}<br/>
        Transaction ID: {{purchase.transaction_id}}
    </div>
    <div class="col mt-4 flex-grow-1 border rounded bg-light-grey d-flex justify-content-around flex-column">
        <div class="col flex-grow-1 p-4 d-flex justify-content-between">
            <span class="text-700">{{purchase.upgrade}}</span>
            <span class="">${{purchase.price}}</span>
        </div>
        <div class="col-auto tax d-flex justify-content-between p-4 flex-shrink-1">
            <span class="text-700">Tax</span><span>${{purchase.tax}}</span>
        </div>
        <div class="col-auto total d-flex justify-content-between p-4 border-top flex-shrink-1">
            <span class="text-700">Total</span><span>${{purchase.total}}</span>
        </div>
    </div>
    <div class="col mt-4 d-flex flex-column text-center">
        <span class="text-700 text-grey">Payment Method</span>
        <span class="text-grey text-100 text-sm">Card (#### #### #### {{purchase.last_four}})</span>
        <span class="text-grey text-100 text-sm my-4">Charges will appear as UsedEverywhere on your credit card statement</span>
    </div>
    <div class="col bg-light-grey text-grey text-xs text-100 text-grey-darken d-flex flex-column pt-12 pb-8 px-4">
        <span class="border-top pt-4 mb-4">This was also sent to <a :href="`mailto:${purchase.email}`" target="_blank">{{purchase.email}}</a>. If you have any question or complaints, please <a target="_blank" href="mailto:feedback@used.ca">contact us</a>.</span>
        <span>Used.ca, 818 Broughton Street, Victoria BC V8W 1E4</span>
    </div>
    <div class="col mt-4 d-flex align-items-center justify-content-center">
        <a href="https://used.ca" class="to-used btn btn-accent d-flex align-items-center">To Used.ca</a>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        purchase: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        purchaseDate() {
            let d = new Date(this.purchase.date)
            return d.toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric"
            })
        }
    }
}
</script>

<style lang="scss">
.to-used {
    &:hover {
        text-decoration: none;
    }
}
</style>