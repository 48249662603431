<template>
  <div>
    <img :src="require('@/assets/logo.svg')" class="logo col-3"/>
    <div class="col-auto h3 text-700 flex-shrink-1 text-center" style="letter-spacing:-0.1rem">
        Error occured with payment
    </div>
    <div class="col-auto text-center text-100">
        You have not been charged for purchase.
    </div>
     <div v-if="purchase" class="col mt-4 flex-grow-1 border rounded bg-light-grey d-flex justify-content-around flex-column">
        <div class="col flex-grow-1 p-4 d-flex justify-content-between">
            <span class="text-700">{{purchase.upgrade}}</span>
            <span class="">${{purchase.price}}</span>
        </div>
        <div class="col-auto tax d-flex justify-content-between p-4 flex-shrink-1">
            <span class="text-700">Tax</span><span>${{purchase.tax}}</span>
        </div>
        <div class="col-auto total d-flex justify-content-between p-4 border-top flex-shrink-1">
            <span class="text-700">Total</span><span>${{purchase.total}}</span>
        </div>
    </div>
    <div class="col bg-light-grey text-grey text-xs text-100 text-grey-darken d-flex flex-column pt-12 pb-8 mt-8 px-4">
        <span>If you have any question or concerns, please <a target="_blank" href="mailto:feedback@used.ca">contact us</a>.</span>
        <span>Used.ca, 818 Broughton Street, Victoria BC V8W 1E4</span>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        purchase: {
            type: Object,
            default: () => ({})
        }
    }
}
</script>

<style>

</style>